import React from 'react';
import './JobsIntro.scss';
import SectionHeading from "../../../common/section-heading/SectionHeading";
import Section from "../../../common/section/Section";


function JobsIntro() {
    var lowerContent = <>
        <a  className={"hiro-job"} target={"_blank"} href={"https://www.linkedin.com/jobs/search/?currentJobId=3980677103&f_C=98712357&geoId=92000000&origin=COMPANY_PAGE_JOBS_CLUSTER_EXPANSION&originToLandingJobPostings=3980677103"}>
            <h3 className={"hiro-job-title"}>Software Engineer</h3>
            <span className={"hiro-job-details"}>On Site - Nashville - $75-$80hr</span>
        </a>
    </>
    
    return (
        <Section className={"jobs-intro"}
                 lowerContent={lowerContent}
        centered={true}>
            <SectionHeading heading={<>Job Openings</>}
                            subtitle={<>Ready to be a <span className={"accent"}><strong>hirō</strong></span></>}
                            description={["At Hirō Tech Talent, we rely on our experienced recruitment team and developer-led vetting process to identify and place qualified candidates into full-time software technology positions. See the available positions below."]}>
                
            </SectionHeading>
        </Section>
    );
}

export default JobsIntro;
 